import React, { useState } from 'react';
import LoginModal from './LoginModal';
import './Navbar.css';

const Navbar = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    setIsLoginModalOpen(true);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-left">
          <a href="/" className="nav-item">首页</a>
          <a href="/code" className="nav-item">热门博客</a>
          <a href="/meeting" className="nav-item">下载</a>
          <a href="/cdn" className="nav-item">关于</a>
        </div>
        <div className="search-container">
          <input type="text" className="search-input" placeholder="搜索..." />
          <button className="search-btn">
            <svg className="search-icon" viewBox="0 0 24 24" width="16" height="16">
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
            </svg>
          </button>
        </div>
        <div className="navbar-right">
          <button className="register-btn">注册</button>
          <button className="login-btn" onClick={handleLoginClick}>登录</button>
        </div>
      </div>
      <LoginModal 
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)} 
      />
    </nav>
  );
};

export default Navbar;
