// 模拟用户数据
const users = [
    {
      id: 1,
      username: 'changxin2048',
      password: '123456',
      nickname: '张三',
      avatar: '/assets/images/avatar1.jpg'
    },
    {
      id: 2,
      username: 'test',
      password: '123456',
      nickname: '李四',
      avatar: '/assets/images/avatar2.jpg'
    }
  ];
  
  export const login = (username, password) => {
    const user = users.find(u => u.username === username && u.password === password);
    if (user) {
      // 登录成功，存储用户信息到 localStorage
      const { password, ...userInfo } = user;
      localStorage.setItem('user', JSON.stringify(userInfo));
      return userInfo;
    }
    return null;
  };
  
  export const logout = () => {
    localStorage.removeItem('user');
  };
  
  export const getCurrentUser = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  };