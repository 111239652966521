import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { marked } from 'marked';
import './ArticleEdit.css';

const ArticleEdit = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    // 加载文章内容
    fetch(`/articles/${id}.md`)
      .then(res => res.text())
      .then(text => {
        setContent(text);
        // 从内容中提取标题
        const titleMatch = text.match(/^#\s+(.+)$/m);
        if (titleMatch) {
          setTitle(titleMatch[1]);
        }
      });
  }, [id]);

  const handleSave = async () => {
    try {
      // 这里需要实现保存逻辑
      // 保存成功后跳转
      const user = JSON.parse(localStorage.getItem('user'));
      navigate(`/user/${user.username}`);
    } catch (error) {
      console.error('保存失败:', error);
    }
  };

  return (
    <div className="editor-container">
      <div className="editor-header">
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="title-input"
          placeholder="输入文章标题..."
        />
        <button className="save-button" onClick={handleSave}>
          保存文章
        </button>
      </div>
      
      <div className="editor-main">
        <div className="editor-section">
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="markdown-editor"
            placeholder="开始写作..."
          />
        </div>
        <div className="preview-section">
          <div 
            className="markdown-preview"
            dangerouslySetInnerHTML={{ __html: marked(content) }}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleEdit;