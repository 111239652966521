// src/components/Toast.js
import React from 'react';
import './Toast.css';

function Toast({ message, show }) {
  return (
    <div className={`toast ${show ? 'show' : ''}`}>
      {message}
    </div>
  );
}

export default Toast;