import React, { useState, useEffect } from 'react';
import './UserChangPD.css';
import { Link, useParams, Navigate, useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../services/authService';
import Toast from '../components/Toast';

function UserChangPD() {
  const navigate = useNavigate();
  const { username } = useParams();
  const currentUser = getCurrentUser();
  const [showToast, setShowToast] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  const isCurrentUserProfile = currentUser && currentUser.username === username;

  useEffect(() => {
    const handleScroll = () => {
      const isBottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
      
      if (isBottom && !atBottom) {
        setAtBottom(true);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 1000);
      } else if (!isBottom) {
        setAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [atBottom]);

  const getUserInfo = () => {
    if (username === 'changxin2048') {
      return {
        avatar: "https://tse3-mm.cn.bing.net/th/id/OIP-C.DbxeK5Iq3vy2VaqiJVdgrQAAAA",
        name: "CHANG",
        level: "LV.4",
        title: "资深产品经理 | 阿里巴巴",
        email: "changxin2048@163.com"
      };
    } else if (username === 'changxin') {
      return {
        avatar: "https://p26-passport.byteacctimg.com/img/mosaic-legacy/3795/3033762272~180x180.awebp",
        name: "CHANG",
        level: "LV.2",
        title: "行业研究工程师 | 阿里巴巴",
        email: "changxin2048@163.com"
      };
    }
  };

  const userInfo = getUserInfo();

  return (
    <div className="user-profile-page">
      <div className="user-profile-container">
        {/* 内容区 */}
        <div className="user-main" >
          {/* 用户信息卡片 */}
          <div className="user-card">
            <div className="user-info">
              <div className="user-info-left">
                <img 
                  src={userInfo.avatar}
                  alt="用户头像" 
                  className="user-avatar"
                />
                <div className="user-info-content" >
                  <div className='user-name1' style={{ display: 'flex', alignItems: 'center' }}>
                    <h2 className="user-name" style={{ marginLeft: 0 }}>{userInfo.name}</h2>
                    <div className="user-badges" style={{ display: 'inline-block', marginLeft: 10 ,paddingBottom: '4px'}}>
                      <span className="badge">{userInfo.level}</span>
                    </div>
                  </div>
                  <div className="user-title" >
                    <i className="fas fa-briefcase"></i> {userInfo.title}
                  </div>
                  <div className='user-email'>
                    <i className="fas fa-envelope"></i> {userInfo.email}
                  </div> 
                </div>
              </div>
              <div className='user-act'>
                <div className="user-actions">
                  <button className="follow-btn">关注</button>
                  <button className="message-btn">私信</button>
                  <button className="more-btn">...</button>
                </div>
              </div>
            </div>
          </div>

          {/* 文章列表区域 */}
          <div className="content-card">
            <div className="tabs">
              <span className="tab active">文章</span>
              <span className="tab">专栏</span>
              <span className="tab">沸点</span>
              <span className="tab">收藏集</span>
              <span className="tab">关注</span>
              <span className="tab">作品</span>
            </div>

            {username === 'changxin2048' ? (
              <div className="articles">
                <Link to="/article/1731832014" className="article-item">
                  <h3>产品设计的六个基本步骤</h3>
                  <p>每一款产品都有其对应的业务流程，因此，作为产品经理，深入了解业务是工作的重中之重...</p>
                  <div className="article-meta">
                    <span>2024-10-21</span>
                    <span><i className="far fa-eye"></i>303</span>
                    <span><i className="far fa-thumbs-up"></i>11</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">产品设计</span>
                      <span className="tag">产品经理</span>
                    </div>
                  </div>
                  
                  {/* 添加编辑按钮 */}
                  {isCurrentUserProfile && (
                    <button 
                      className="edit-button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigate(`/article/edit/1731832014`);
                      }}
                    >
                      <i className="fas fa-edit"></i> 编辑
                    </button>
                  )}
                </Link>

                <Link to="/article/1731832024" className="article-item">
                  <h3>产品经理与项目经理有什么区别？</h3>
                  <p>产品经理和项目经理这两个角色常常被提及，但许多人对它们之间的差异并不十分清楚...</p>
                  <div className="article-meta">
                    <span>2024-10-15</span>
                    <span><i className="far fa-eye"></i>267</span>
                    <span><i className="far fa-thumbs-up"></i>9</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">产品设计</span>
                      <span className="tag">项目经理</span>
                      <span className="tag">产品经理</span>
                    </div>
                  </div>
                </Link>

                <Link to="/article/1731832034" className="article-item">
                  <h3>B端产品设计怎么做？</h3>
                  <p>B端产品面向企业或组织客户，与面向个人消费者的C端产品截然不同...</p>
                  <div className="article-meta">
                    <span>2024-09-20</span>
                    <span><i className="far fa-eye"></i>319</span>
                    <span><i className="far fa-thumbs-up"></i>8</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">产品设计</span>
                    </div>
                  </div>
                </Link>

                <Link to="/article/1731832044" className="article-item">
                  <h3>B端产品设计经验总结</h3>
                  <p>在当今数字化浪潮下，B端产品的重要性日益凸显…## vue3</p>
                  <div className="article-meta">
                    <span>2024-09-15</span>
                    <span><i className="far fa-eye"></i>142</span>
                    <span><i className="far fa-thumbs-up"></i>9</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">产品设计</span>
                      <span className="tag">数字化</span>
                    </div>
                  </div>
                </Link>

                <Link to="/article/1731832054" className="article-item">
                  <h3>🎉产品设计工具总结😎</h3>
                  <p>聊聊产品设计那些超好用的工具🧰，这些可都是我在设计一线摸爬滚打多年…</p>
                  <div className="article-meta">
                    <span>2024-09-01</span>
                    <span><i className="far fa-eye"></i>1463</span>
                    <span><i className="far fa-thumbs-up"></i>109</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">产品设计</span>
                      <span className="tag">设计工具</span>
                    </div>
                  </div>
                </Link>

                <Link to="/article/1731832064" className="article-item">
                  <h3>思考：大模型在B端产品的应用</h3>
                  <p>大模型C端的应用已经遍地开花，对于B端产品来说，这既是个超级酷炫的机遇…</p>
                  <div className="article-meta">
                    <span>2024-08-07</span>
                    <span><i className="far fa-eye"></i>253</span>
                    <span><i className="far fa-thumbs-up"></i>8</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">B端产品</span>
                      <span className="tag">AI</span>
                    </div>
                  </div>
                </Link>
                <Link to="/article/1731832074" className="article-item">
                  <h3>什么是数据产品经理</h3>
                  <p>在当今数字化浪潮的席卷下，数据产品经理这个角色愈发凸显其重要性……</p>
                  <div className="article-meta">
                    <span>2024-08-01</span>
                    <span><i className="far fa-eye"></i>303</span>
                    <span><i className="far fa-thumbs-up"></i>12</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">产品设计</span>
                      <span className="tag">产品经理</span>
                    </div>
                  </div>
                </Link>
                <Link to="/article/1731832084" className="article-item">
                  <h3>人人都是产品经理--产品经理的自我修养</h3>
                  <p>产品经理的自我修养，是产品经理在职业生涯中不断成长和提升的过程…</p>
                  <div className="article-meta">
                    <span>2024-07-17</span>
                    <span><i className="far fa-eye"></i>243</span>
                    <span><i className="far fa-thumbs-up"></i>14</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">产品设计</span>
                      <span className="tag">产品经理</span>
                    </div>
                  </div>
                </Link>
                <Link to="/article/1731832094" className="article-item">
                  <h3>如何结合 AI 把所有产品重新做一遍</h3>
                  <p>嘿，朋友！2024年了，AI对产品设计那影响可太大啦，就像一阵超强的旋风席卷而来。在产品设计的各个环节，从最初的构思到细节的雕琢，AI都如影随形。无论是挖掘用户需求、优化交互体验，还是创新功能设计，AI将让产品设计领域充满了无限可能和新的机遇。</p>
                  <div className="article-meta">
                    <span>2024-06-20</span>
                    <span><i className="far fa-eye"></i>733</span>
                    <span><i className="far fa-thumbs-up"></i>21</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">产品设计</span>
                      <span className="tag">AI</span>
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="articles">
                <Link to="/article/2431832024" className="article-item">
                  <h3>如何做好产业（行业）研究</h3>
                  <p>当今竞争激烈且瞬息万变的商业环境中，产业（行业）研究犹如一盏明灯...</p>
                  <div className="article-meta">
                    <span>2024-10-21</span>
                    <span><i className="far fa-eye"></i>173</span>
                    <span><i className="far fa-thumbs-up"></i>23</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">行业研究</span>
                      <span className="tag">产业研究</span>
                    </div>
                  </div>
                </Link>
                <Link to="/article/2431832034" className="article-item">
                  <h3>做行业研究，如何有效搜集数据？</h3>
                  <p>这就好比咱要盖一座大厦，数据就是那一块块的砖头，没有足够靠谱的砖头，大厦可就摇摇欲坠...</p>
                  <div className="article-meta">
                    <span>2024-10-08</span>
                    <span><i className="far fa-eye"></i>328</span>
                    <span><i className="far fa-thumbs-up"></i>19</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">行业研究</span>
                      <span className="tag">产业研究</span>
                    </div>
                  </div>
                </Link>
                <Link to="/article/2431832044" className="article-item">
                  <h3>B端产品和C端产品开发有什么不同？</h3>
                  <p>B端和C端产品就像两条不同轨道上跑的列车，虽然产品开发的流程和步骤基本一样，但也哟很多不同之处...</p>
                  <div className="article-meta">
                    <span>2024-09-20</span>
                    <span><i className="far fa-eye"></i>204</span>
                    <span><i className="far fa-thumbs-up"></i>13</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">行业研究</span>
                      <span className="tag">产业研究</span>
                    </div>
                  </div>
                </Link>
                <Link to="/article/2431832054" className="article-item">
                  <h3>如何写一份优秀的行业研究报告？</h3>
                  <p>它就像是咱在行业研究领域的一张名片，一份优秀的报告能让你在老板、客户或者同行面前闪闪发光…</p>
                  <div className="article-meta">
                    <span>2024-09-10</span>
                    <span><i className="far fa-eye"></i>428</span>
                    <span><i className="far fa-thumbs-up"></i>32</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">行业研究</span>
                      <span className="tag">产业研究</span>
                    </div>
                  </div>
                </Link>
                <Link to="/article/2431832064" className="article-item">
                  <h3>行业研究报告模板</h3>
                  <p>详细的行业研究报告模板，可以参考使用…</p>
                  <div className="article-meta">
                    <span>2024-08-24</span>
                    <span><i className="far fa-eye"></i>409</span>
                    <span><i className="far fa-thumbs-up"></i>32</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">行业研究</span>
                      <span className="tag">产业研究</span>
                    </div>
                  </div>
                </Link>
                <Link to="/article/2431832074" className="article-item">
                  <h3>AI 能力助力行业研究</h3>
                  <p>AI 能力到底在哪些方面能帮我们把行业研究做得更准确更高效...</p>
                  <div className="article-meta">
                    <span>2024-08-10</span>
                    <span><i className="far fa-eye"></i>533</span>
                    <span><i className="far fa-thumbs-up"></i>13</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">行业研究</span>
                      <span className="tag">产业研究</span>
                    </div>
                  </div>
                </Link>
                <Link to="/article/2431832084" className="article-item">
                  <h3>做行业研究必须要知道的数据搜集平台</h3>
                  <p>在进行行业研究时，数据可是必不可少的基础。下面就给大家介绍一些超有用的数据搜集平台…</p>
                  <div className="article-meta">
                    <span>2024-07-15</span>
                    <span><i className="far fa-eye"></i>237</span>
                    <span><i className="far fa-thumbs-up"></i>13</span>
                    <span><i className="far fa-comment"></i>评论</span>
                    <div className="article-tags">
                      <span className="tag">行业研究</span>
                      <span className="tag">产业研究</span>
                    </div>
                  </div>
                </Link>
              </div>
              
            )}
          </div>

          <div 
            className="load-more"
            onClick={() => {
              setShowToast(true);
              setTimeout(() => setShowToast(false), 1000);
            }}
          >
            <i className="fas fa-chevron-down"></i> &nbsp; 加载更多
          </div>
        </div>

        {/* 右侧成就信息 */}
        <div className="user-achievements" style={{ padding: '20px', borderRadius: '8px',  maxHeight: '230px', overflowY: 'auto' }}>
          <h3 style={{ marginBottom: '15px', fontWeight: 'bold', borderBottom: '1px solid #e0e0e0', paddingBottom: '10px', fontSize: '16px' }}>个人成就</h3>
          <ul className='achievement-list'>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <span style={{ marginRight: '10px' }}>&#x1F3C6;</span> 优秀创作者
            </li>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <span style={{ marginRight: '10px' }}>&#x1F3C6;</span> 创作内容 91
            </li>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <span style={{ marginRight: '10px' }}>&#x1F44D;</span> 文章被点赞 1,412
            </li>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <span style={{ marginRight: '10px' }}>&#x1F4F0;</span> 文章被阅读 25,656
            </li>
            <li style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '10px' }}>&#x1F4C8;</span> 热力值 13,888
            </li>
          </ul>
        </div>
      </div>
      <Toast message="登录后查看更多" show={showToast} />
    </div>
  );
}

export default UserChangPD;
