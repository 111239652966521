import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginModal.css';

const LoginModal = ({ isOpen, onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // 模拟用户数据
  const users = [
    {
      id: 1,
      username: 'changxin2048',
      password: '123456',
      nickname: '张三',
      avatar: '/assets/images/avatar1.jpg'
    },
    {
      id: 2,
      username: '2',
      password: '2',
      nickname: '李四',
      avatar: '/assets/images/avatar2.jpg'
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // 验证用户名和密码
    const user = users.find(u => u.username === username && u.password === password);
    
    if (user) {
      // 登录成功，存储用户信息到 localStorage（排除密码）
      const { password: _, ...userInfo } = user;
      localStorage.setItem('user', JSON.stringify(userInfo));
      
      // 关闭弹窗
      onClose();
      
      // 使用 username 进行跳转
      navigate(`/user/${user.username}`);
    } else {
      setError('用户名或密码错误');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>&times;</button>
        <h2>登录</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              placeholder="用户名"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="密码"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-btn">登录</button>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;