import React, { useState, useEffect } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import Toast from '../components/Toast'; // 引入Toast组件

const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 11) {
        return "早上好！";
    } else if (hour >= 11 && hour < 13) {
        return "中午好！";
    } else if (hour >= 13 && hour < 18) {
        return "下午好！";
    } else {
        return "晚上好！";
    }
};

const getCurrentWeekDates = () => {
    const today = new Date();
    const startOfWeek = today.getDate() - today.getDay(); // 获取本周的第一天（周日）
    const weekDates = [];

    for (let i = 0; i < 7; i++) {
        const date = new Date(today.setDate(startOfWeek + i));
        weekDates.push(date.getDate()); // 只推入日期
    }

    return weekDates;
};

const Home = () => {
    const [showToast, setShowToast] = useState(false);
    const [atBottom, setAtBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isBottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;

            if (isBottom && !atBottom) {
                setAtBottom(true);
                setShowToast(true);
                setTimeout(() => setShowToast(false), 1000); // 1秒后隐藏Toast
            } else if (!isBottom) {
                setAtBottom(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [atBottom]);

    const weekDates = getCurrentWeekDates(); // 获取当前周的日期
    const today = new Date();
    const month = today.toLocaleString('default', { month: 'long' }); // 获取当前月份的完整名称
    const year = today.getFullYear(); // 获取当前年份

    return (
        <div className="home-container">
            <div className="home-layout">
                {/* 左侧导航栏 */}
                <div className="left-sidebar">
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-robot sidebar-icon"></i>
                        </div>
                        <span className="menu-text">
                            大模型
                            <span className="tag-new">NEW</span>
                        </span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-heart sidebar-icon"></i>
                        </div>
                        <span className="menu-text">关注</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-th-large sidebar-icon"></i>
                        </div>
                        <span className="menu-text">综合</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-server sidebar-icon"></i>
                        </div>
                        <span className="menu-text">后端</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-code sidebar-icon"></i>
                        </div>
                        <span className="menu-text">前端</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fab fa-android sidebar-icon"></i>
                        </div>
                        <span className="menu-text">Android</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fab fa-apple sidebar-icon"></i>
                        </div>
                        <span className="menu-text">iOS</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-brain sidebar-icon"></i>
                        </div>
                        <span className="menu-text">人工智能</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-tools sidebar-icon"></i>
                        </div>
                        <span className="menu-text">开发工具</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-laptop-code sidebar-icon"></i>
                        </div>
                        <span className="menu-text">代码人生</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-book sidebar-icon"></i>
                        </div>
                        <span className="menu-text">阅读</span>
                    </div>
                    <div className="sidebar-item">
                        <div className="icon-wrapper">
                            <i className="fas fa-chart-line sidebar-icon"></i>
                        </div>
                        <span className="menu-text">排行榜</span>
                    </div>
                </div>

                {/* 中间内容区 */}
                <div className="main-content">
                    <div className="content-tabs">
                        <span className="tab active">推荐</span>
                        <span className="tab">最新</span>
                    </div>
                    <div className="article-list">
                        <div className="article-item">
                            <div className="article-main">
                                <Link to="/article/2431832014" className="article-link">
                                <h3>程序员｜2024年11月复盘</h3>
                                <p>年底复盘又开始了，我只完成了年初的计划一半，这该怎么办呢...</p>
                                </Link>
                                <div className="article-meta">
                                    <span className="author">码农小张</span>
                                    <span className="views">
                                        <i className="far fa-eye"></i> 11k
                                    </span>
                                    <span className="likes">
                                        <i className="far fa-thumbs-up"></i> 141
                                    </span>
                                    <div className="tags">
                                    <span className="tag">后端</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="article-item">
                            <div className="article-main">
                                <h3>开发小同学的源操作，还好被我发现了</h3>
                                <p>大家好，我是程序员鱼皮。今天给大家分享一个致命的团队开发坑，最近在代码评审...</p>
                                <div className="article-meta">
                                    <span className="author">程序员鱼皮</span>
                                    <span className="views">
                                        <i className="far fa-eye"></i> 43k
                                    </span>
                                    <span className="likes">
                                        <i className="far fa-thumbs-up"></i> 298
                                    </span>
                                    <div className="tags">
                                    <span className="tag">前端</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="article-item">
                            <div className="article-main">
                                <h3>我的创业故事：两年内从零到每4.5万美元</h3>
                                <p>大家好，我是独立开发者。今天分享一个独特的创业经历，是如何从零开始到达每月4.5万美元收入的...</p>
                                <div className="article-meta">
                                    <span className="author">独立开发者</span>
                                    <span className="views">
                                        <i className="far fa-eye"></i> 46k
                                    </span>
                                    <span className="likes">
                                        <i className="far fa-thumbs-up"></i> 662
                                    </span>
                                    <div className="tags">
                                    <span className="tag">创业</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="article-item">
                            <div className="article-main">
                                <h3>老板想集成地图又不想花钱，于是让我...</h3>
                                <p>最近在数字化时代，地图服务已成为各类应用的标配。无论是导航、位置分享还是地理数据分析...</p>
                                <div className="article-meta">
                                    <span className="author">技术老饕</span>
                                    <span className="views">
                                        <i className="far fa-eye"></i> 5.9k
                                    </span>
                                    <span className="likes">
                                        <i className="far fa-thumbs-up"></i> 81
                                    </span>
                                    <div className="tags">
                                    <span className="tag">后端</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="article-item">
                            <div className="article-main">
                                <h3>服了，一线城市的后端都这样了吗！？</h3>
                                <p>主人公是一名工作在一线城市的后端开发工程师，最近2年用Golang，之前是做Java...</p>
                                <div className="article-meta">
                                    <span className="author">后端老王</span>
                                    <span className="views">
                                        <i className="far fa-eye"></i> 113k
                                    </span>
                                    <span className="likes">
                                        <i className="far fa-thumbs-up"></i> 592
                                    </span>
                                    <div className="tags">
                                    <span className="tag">Go</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="article-item">
                            <div className="article-main">
                                <h3>低谷期，什么是最好的理财方式？买房、炒股、存钱？</h3>
                                <p>2024年云端环境可能会更加困难化下，程序员该如何理财？如何度过这个阶段？对于个人最好的方案是...</p>
                                <div className="article-meta">
                                <span className="author">jack18 </span>
                                    <span className="views">
                                        <i className="far fa-eye"></i> 11k
                                    </span>
                                    <span className="likes">
                                        <i className="far fa-thumbs-up"></i> 141
                                    </span>
                                    <div className="tags">
                                    <span className="tag">程序人生</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="article-item">
                            <div className="article-main">
                                <h3>大惨了，系统重构后出了线上事故，背了C绩效，不堪回首的两个月</h3>
                                <p>4 年前的一次线上事故，把三个月加班加点搭下的苦苦全部毁光。项目重构并不是简单的事情，要足够警惕和重视...</p>
                                <div className="article-meta">
                                    <span className="author">五阳</span>
                                    <span className="views"><i className="far fa-eye"></i> 15k</span>
                                    <span className="likes"><i className="far fa-thumbs-up"></i> 214</span>
                                    <div className="tags">
                                        <span className="tag">后端</span>
                                        <span className="tag">架构</span>
                                        <span className="tag">Java</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="article-item">
                            <div className="article-main">
                                <h3>有了 Spring AI，Java 开发AI应用也就简单多了</h3>
                                <p>Spring 官方自从发布了 Spring AI，AI 界的门儿算是被彻底踹飞了！作为一名Javaer，以为这辈子跟AI无缘了，没...</p>
                                <div className="article-meta">
                                    <span className="author">王二蛋呀</span>
                                    <span className="views"><i className="far fa-eye"></i> 670</span>
                                    <span className="likes"><i className="far fa-thumbs-up"></i> 13</span>
                                    <div className="tags">
                                        <span className="tag">人工智能</span>
                                        <span className="tag">Java</span>
                                        <span className="tag">Spring Boot</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="article-item">
                            <div className="article-main">
                                <h3>直接使用git pull拉取代码，被同事狠狠地diss了！</h3>
                                <p>往期精彩文章：# 拿客户电脑，半小时完成杯播组件开发！被公司奖励500！背景 今天干活...</p>
                                <div className="article-meta">
                                    <span className="author">石小石Orz</span>
                                    <span className="views"><i className="far fa-eye"></i> 56k</span>
                                    <span className="likes"><i className="far fa-thumbs-up"></i> 1.0k</span>
                                    <div className="tags">
                                        <span className="tag">Git</span>
                                        <span className="tag">GitHub</span>
                                        <span className="tag">GitLab</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 右侧边栏 */}
                <div className="right-sidebar">
                    {/* 签到卡片 */}
                    <div className="sidebar-card welcome-card">
                        <div className="welcome-content">
                            <div className="welcome-text">
                                <h3>{getGreeting()}</h3>
                                <p>点亮在社区的每一天</p>
                            </div>
                            <button className="sign-in-btn">去签到</button>
                        </div>
                    </div>

                    {/* 新增日历组件 */}
                    <div className="sidebar-card calendar-card">
                        
                        <div className="calendar-content">
                            <div className="calendar-header">
                                <span className="month-selector">
                                    <i className="fas fa-chevron-left"></i>
                                    {`${month} ${year}`}
                                    <i className="fas fa-chevron-right"></i>
                                </span>
                            </div>
                            <div className="calendar-grid">
                                <div className="weekday">日</div>
                                <div className="weekday">一</div>
                                <div className="weekday">二</div>
                                <div className="weekday">三</div>
                                <div className="weekday">四</div>
                                <div className="weekday">五</div>
                                <div className="weekday">六</div>
                                {/* 日期格子示例 */}
                                {weekDates.map((date, index) => (
                                    <div key={index} className={`date-cell ${date === new Date().getDate() ? 'active' : ''}`}>
                                        {date}
                                    </div>
                                ))}
                                {/* 更多日期... */}
                            </div>
                            <div className="calendar-footer">
                                
                            </div>
                        </div>
                    </div>

                    {/* 文章榜单 */}
                    <div className="sidebar-card ranking-card">
                        <div className="card-header">
                            <span className="card-title">
                                <i className="fas fa-file-alt"></i>
                                文章榜
                            </span>
                            <span className="refresh-btn">
                                <i className="fas fa-sync-alt"></i>
                                换一换
                            </span>
                        </div>
                        <div className="ranking-list">
                            <div className="ranking-item">
                                <span className="rank-num">1</span>
                                <span className="rank-title">wangEditor，从开源、停更到...</span>
                            </div>
                            <div className="ranking-item">
                                <span className="rank-num">2</span>
                                <span className="rank-title">什么是微服务？如何搭建一套...</span>
                            </div>
                            <div className="ranking-item">
                                <span className="rank-num">3</span>
                                <span className="rank-title">autoUno：最直观的UnoCss...</span>
                            </div>
                            <div className="ranking-item">
                                <span className="rank-num">4</span>
                                <span className="rank-title">如何实现图片阅读即焚功能的...</span>
                            </div>
                            <div className="ranking-item">
                                <span className="rank-num">5</span>
                                <span className="rank-title">独立开发上班后：我的故事，...</span>
                            </div>
                            <div className="see-more">
                                查看更多&gt;
                            </div>
                        </div>
                    </div>

                    {/* 活动广告卡片1 */}
                    <div className="sidebar-card ad-card">
                        <img 
                            src="https://images.pexels.com/photos/17483906/pexels-photo-17483906/free-photo-of-an-artist-s-illustration-of-artificial-intelligence-ai-this-image-represents-the-concept-of-artificial-general-intelligence-agi-it-was-created-by-domhnall-malone-as-part-of-the-visua.png?auto=compress&cs=tinysrgb&w=600" 
                            alt="AI开发者大会" 
                            title=" 以 AI 助力快速开发者"
                        />
                    </div>

                    {/* 活动广告卡片2 */}
                    <div className="sidebar-card ad-card">
                        <img 
                            src="https://images.pexels.com/photos/17485706/pexels-photo-17485706/free-photo-of-an-artist-s-illustration-of-artificial-intelligence-ai-this-image-visualises-the-input-and-output-of-neural-networks-and-how-ai-systems-perceive-data-it-was-created-by-rose-pilkington.png?auto=compress&cs=tinysrgb&w=600" 
                            alt="AI刷题活动" 
                            title="生命科学前沿"
                        />
                    </div>

                    {/* 活动广告卡片3 */}
                    <div className="sidebar-card ad-card">
                        <img 
                            src="https://images.pexels.com/photos/25626446/pexels-photo-25626446/free-photo-of-black-and-white-geometric-representation-of-data.jpeg?auto=compress&cs=tinysrgb&w=600" 
                            alt="AI签约季活动" 
                            title="人工智能签约季 - 创作热高潮涨"
                        />
                    </div>

                    {/* 右侧推荐话题卡片 */}
                    <div className="sidebar-card topic-card">
                        <div className="card-header">
                            <h3 className="card-title">推荐话题</h3>
                            <span className="refresh-btn">
                                <i className="fas fa-sync-alt"></i> 换一换
                            </span>
                        </div>
                        <div className="topic-list">
                            <div className="topic-item">
                                <span className="topic-icon">🔥</span>
                                <span className="topic-name">#挑战每日一条沸点#</span>
                                <span className="topic-count">11.7m</span>
                            </div>
                            <div className="topic-item">
                                <span className="topic-icon">🔥</span>
                                <span className="topic-icon">🎖️</span>
                                <span className="topic-name">#豆包MarsCode#</span>
                                <span className="topic-count">394k</span>
                            </div>
                            <div className="topic-item">
                                <span className="topic-icon">🔥</span>
                                <span className="topic-name">#创作者训练营#</span>
                                <span className="topic-count">6.3m</span>
                            </div>
                            <div className="topic-item">
                                <span className="topic-icon">🔥</span>
                                <span className="topic-name">#每日快讯#</span>
                                <span className="topic-count">7.0m</span>
                            </div>
                            <div className="topic-item">
                                <span className="topic-icon">🔥</span>
                                <span className="topic-name">#我的后端Java之路#</span>
                                <span className="topic-count">26k</span>
                            </div>
                            <div className="topic-item">
                                <span className="topic-icon">🔥</span>
                                <span className="topic-name">#每天一个知识点#</span>
                                <span className="topic-count">26.9m</span>
                            </div>
                            <div className="topic-item">
                            <span className="topic-icon">🔥</span>
                                <span className="topic-name">#金石计划证文活动#</span>
                                <span className="topic-count">26.3m</span>
                            </div>
                            <div className="topic-item">
                            <span className="topic-icon">🔥</span>
                                <span className="topic-name">#新人报道#</span>
                                <span className="topic-count">19.1m</span>
                            </div>
                            {/* 更多话题项... */}
                        </div>
                        <div className="see-more">
                            查看更多 <i className="fas fa-angle-right"></i>
                        </div>
                    </div>

                    {/* 底部信息卡片 */}
                    <div className="sidebar-card footer-card">
                        <div className="footer-links">
                            <a href="#">用户协议</a>
                            <a href="#">隐私政策</a>
                            <a href="#">关于我们</a>
                        </div>
                        <div className="footer-links second-row">
                            <a href="#">使用指南</a>
                            <a href="#">友情链接</a>
                            <a href="#">更多文章</a>
                        </div>
                        <div className="footer-info">
                            <p>举报邮箱：feedback@xitu.io</p>
                            <p>座机电话：010-83434395</p>
                            <p>京ICP备：18012699号-3</p>
                            <p>京ICP证：京B2-20191272</p>
                           
                        </div>
                    </div>
                </div>
            </div>
            <Toast message="登录后查看更多" show={showToast} />
        </div>
    );
};

export default Home;
