import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import ArticleDetail from './pages/ArticleDetail';
import UserChangPD from './pages/UserChangPD';
import UserProfile from './pages/UserProfile';
import { getCurrentUser } from './services/authService';
import ArticleEdit from './pages/ArticleEdit';

// 受保护的路由组件
const ProtectedRoute = ({ children }) => {
  const user = getCurrentUser();
  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};

// 创建一个包含导航栏的布局组件
function Layout({ children }) {
  const location = useLocation();
  const isEditPage = location.pathname.includes('/article/edit/');

  return (
    <div className="App">
      {!isEditPage && <Navbar />}
      {children}
    </div>
  );
}

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/article/:id" element={<ArticleDetail />} />
          <Route path="/user/:username" element={<UserChangPD />} />
          <Route path="/user/:username" element={<UserProfile />} />

          <Route
            path="/user/:username"
            element={
              <ProtectedRoute>
                <UserChangPD />
              </ProtectedRoute>
            }
          />
          <Route
            path="/article/edit/:id"
            element={
              <ProtectedRoute>
                <ArticleEdit />
              </ProtectedRoute>
            }
          />
          <Route path="/user/:username" element={<UserProfile />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
