import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import marked from 'marked';
import './ArticleDetail.css';

function ArticleDetail() {
  const { id } = useParams();
  const [content, setContent] = useState('');
  
  useEffect(() => {
    window.scrollTo(0, 0);
    // 配置 marked
    marked.setOptions({
      breaks: true,
      gfm: true
    }
  );

    // 根据 id 参数从 public/articles 文件夹加载对应的 markdown 文件
    fetch(`/articles/${id}.md`)
      .then(response => {
        console.log('fetch response:', response);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(text => {
        console.log('获取到的 Markdown 内容:', text);
        const htmlContent = marked.parse(text);
        console.log('转换后的 HTML:', htmlContent);
        setContent(htmlContent);
      })
      .catch(error => {
        console.error('加载或转换出错:', error);
        setContent('<p>加载出错</p>');
      });
  }, [id]);

  return (
    <div className="article-detail-page">
      <div className="article-detail-container">
        {/* 左侧操作栏 */}
        <div className="action-bar">
          <div className="action-item">
            <i className="far fa-thumbs-up"></i>
            {/* <span className="action-count">11</span> */}  
          </div>
          <div className="action-item">
            <i className="far fa-comment"></i>
          </div>
          <div className="action-item">
            <i className="far fa-star"></i>
          </div>
          <div className="action-item">
            <i className="fas fa-share"></i>
          </div>
          <div className="action-item">
            <i className="far fa-bookmark"></i>
          </div>
        </div>

        {/* 中间文章内容区 */}
        <div className="article-content">
          <div className='article-card'>
          {/* 文章卡片 */}
             
            {/*  
            <h1 className="article-title">【CloudWeGo】字节跳动 Golang 微服务 HTTP 框架 Hertz</h1>
          <div className="article-meta">
              <div className="meta-left">
                <span className="author">程序员干饭</span>
                <span className="post-time">2024-11-11</span>
                <span className="read-time">
                  <i className="far fa-eye"></i>
                  阅读 3分钟
                </span>
              </div>
            </div> */}

            <div 
              className="article-body markdown-content"
              dangerouslySetInnerHTML={{ __html: content || '加载中...' }}
            />
          </div>
         {/* 留言卡片 */}
          <div className="comment-card">
            
            <div>
              <div className="comment-container">
                <img 
                  src="//lf-web-assets.juejin.cn/obj/juejin-web/xitu_juejin_web/58aaf1326ac763d8a1054056f3b7f2ef.svg" 
                  alt="" 
                  className="comment-avatar"
                />
                <div className="comment-input-container">
                  <input 
                    type="text" 
                    className="comment-input" 
                    placeholder="登录/注册 即可发布评论！" 
                    disabled
                  />
                  <button className="login-register-btn">登录 / 注册</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 右侧作者信息和目录 */}
        <div className="article-sidebar">
          {/* 作者信息卡片 */}
          <div className="author-card">
            <div className="author-info">
              <img src="https://tse3-mm.cn.bing.net/th/id/OIP-C.DbxeK5Iq3vy2VaqiJVdgrQAAAA" alt="" className="author-avatar" style={{ margin: '0px' }} />
              <div className="author-meta" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="author-name">
                  CHANG
                </div>
              </div>
            </div>
            <div className="author-stats">
              <div className="stat-item">
                <div className="stat-value">91</div>
                <div className="stat-label">文章</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">25k</div>
                <div className="stat-label">阅读</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">219</div>
                <div className="stat-label">粉丝</div>
              </div>
            </div>
            <div className="author-actions">
              <button className="btn-follow">关注</button>
              <button className="btn-message">私信</button>
            </div>
          </div>

          {/* 文章目录 
          <div className="article-toc">
            <div className="toc-header">
              <span>目录</span>
              <span className="toc-collapse">收起</span>
            </div>
            <div className="toc-list">
              <div className="toc-item active">为什么选择 Hertz</div>
              <div className="toc-item">程序特点</div>
              <div className="toc-item">Hertz</div>
              <div className="toc-item">demo演示</div>
              <div className="toc-item">快速上手</div>
            </div>
          </div>
          */}
        </div>
      </div>
    </div>
  );
}

export default ArticleDetail;
